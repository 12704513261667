import React from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'

const FeaturesPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            features {
              title
              description
              url
            }
          }
        }
      }
    }
  `);


  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.pages.features.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`Features - ${data.site.siteMetadata.title}`}/>
          <meta name="description" content={data.site.siteMetadata.pages.features.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.pages.features.url} />
      </Helmet>
      <Layout>
          <h1>Features</h1>
      </Layout>  
    </>
  )
}

export default FeaturesPage
